<template>
  <div>
    <el-autocomplete
      v-model="address"
      :fetch-suggestions="getSuggestions"
      placeholder="请输入详细地址"
      :trigger-on-focus="false"
      @select="setSuggestion"
      @change="searchByKeyword"
      class="map_seo"
    />
    <div class="tx_map" id="TXMap"></div>
  </div>
</template>
<script>
import { TMaps } from './map' // 引入地图方法
export default {
  props: {
    centerPoint: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      map: null, // 地图
      mapCenter: {}, // 地图中心
      search: null, // 地点搜索类
      suggest: null, // 关键字输入提示类
      geocoder: null, // 正逆地址解析类      
      markers: null, // 坐标点标识图标
      address: '', // 地址
      location: '', // 坐标
      ad_info: {}, // 回显的地址信息
    }
  },
  created () {
    // 设置初始地图中心
    this.mapCenter = { lat: 30.474403, lng: 114.35554, address: "华中农业大学" }
  },
  mounted () {
    this.init() // 进口
  },
  destroyed () {
    this.map.destroy() // 销毁地图
  },
  watch: {
    centerPoint: {
      handler (val) {
        // 必须含有坐标变化才能变更定位
        if (val.lat && val.lng) {
          const that = this
          let lat = val.lat ? Number(val.lat).toFixed(6) : this.mapCenter.lat
          let lng = val.lng ? Number(val.lng).toFixed(6) : this.mapCenter.lng
          let address = val.address ? val.address : this.mapCenter.address

          let newCenter = new TMap.LatLng(lat, lng) // 新中心坐标
          setTimeout(() => {
            that.map.setCenter(newCenter) // 更新中心坐标
            that.markers.setGeometries([{ position: newCenter }]) // 更新中心标记
            // that.resolveAddresses({ location: newCenter }); // 更新坐标位置
            that.$nextTick(() => {
              that.address = address
              that.ad_info = {
                lat: lat,
                lng: lng,
                address: address
              }
              that.$emit('point', that.ad_info) // 给父组件传值
            })
          }, 500)
        }
      },
      deep: true,
    },
  },
  methods: {
    init () {
      const that = this
      TMaps('XI4BZ-UA6WQ-5JX57-GLPJ5-MPZOO-EXFVV').then((TMap) => {
        const center = new TMap.LatLng(this.mapCenter.lat, this.mapCenter.lng) // 定义地图中心点坐标
        // 创建Map实例赋予变量（map），调用 TMap.Map() 构造函数创建地图
        this.map = new TMap.Map(document.getElementById('TXMap'), {
          center: center, // 设置地图中心点坐标
          zoom: 17 // 设置地图缩放级别
        })
        // 创建正逆地址解析类(必须开通权限 https://lbs.qq.com/faq/serverFaq/webServiceKey)
        this.geocoder = new TMap.service.Geocoder()
        // { location: center }; {address:'湖北省武汉市洪山区文治街516号'}
        // this.resolveAddresses({ location: center }) // 回显默认地址
        // 创建建一个地点搜索类
        this.search = new TMap.service.Search({ pageSize: 10 })
        // 创建一个关键字输入提示类
        this.suggest = new TMap.service.Suggestion({
          pageSize: 10, // 返回结果每页条目数
          // region: '武汉', // 限制城市范围
          // regionFix: true, // 搜索无结果时是否固定在当前城市
        })
        // 创建标注点
        this.markers = new TMap.MultiMarker({
          map: this.map,
          geometries: [// 点标记数据数组
            // { position: center } // 中心坐标
          ]
        })
        // 绑定点击事件
        this.map.on("click", function (evt) {
          let lat = evt.latLng.getLat().toFixed(6)
          let lng = evt.latLng.getLng().toFixed(6)
          let newCenter = new TMap.LatLng(Number(lat), Number(lng)) // 更新中心坐标
          that.markers.setGeometries([{ position: newCenter }]) // 更新中心标记
          that.resolveAddresses({ location: newCenter })
        })

      })
    },
    // 解析地图地址
    async resolveAddresses (obj) {
      const flag = JSON.stringify(obj).indexOf('location')
      let res = null
      if (flag != -1) {
        res = await this.geocoder.getAddress(obj) // 将给定的坐标位置转换为地址
      } else {
        res = await this.geocoder.getLocation(obj) // 将给定的地址转换为坐标位置        
        res.result.address = obj.address
      }
      // //console.log("坐标转换", res.result);
      this.address = res.result.address // 获取详细地址
      this.location = res.result.location // 获取具体坐标
      this.ad_info = {
        lat: res.result.location.lat,
        lng: res.result.location.lng,
        address: res.result.address
      }
      this.$emit('point', this.ad_info) // 给父组件传值
    },

    // 关键字返回的建议
    getSuggestions (queryString, cb) {
      // //console.log("关键字输入", queryString);
      this.suggest.getSuggestions({ keyword: queryString, location: this.map.getCenter() }).then((result) => {
        let sl = [] // 自定义查询数组，接收条件
        if (result.data.length > 0) {
          for (let i = 0; i < result.data.length; i++) {
            let so = {
              value: result.data[i].title,
              address: result.data[i].address,
              point: result.data[i].location,
            }
            sl.push(so)
          }
        }
        cb(sl)
      }).catch((error) => {
        //console.log(error)
      })
    },
    // 关键字建议选择
    setSuggestion (item) {
      // //console.log("关键字建议选择", item);
      // 更新坐标位置
      let newCenter = new TMap.LatLng(item.point.lat, item.point.lng)
      this.map.setCenter(newCenter)
      this.markers.setGeometries([{ position: newCenter }]) // 更新中心标记
      // 给父组件传值
      this.ad_info = {
        lng: item.point.lng,
        lat: item.point.lat,
        address: item.address,
      }
      this.$emit("point", this.ad_info) // 给父组件传值
    },
    // 关键字搜索
    searchByKeyword () {
      // //console.log("关键字搜索");
      const that = this
      this.search.searchRectangle({ keyword: this.address, bounds: this.map.getBounds() }).then((result) => {
        // //console.log("关键字搜索信息",result);
        result.data.forEach((item, index) => {
          const geometries = that.markers.getGeometries()
          geometries.push({
            id: String(index),
            position: item.location,
          })
          that.markers.updateGeometries(geometries)
        })
      })
    },
  },
};
</script>
<style>
.map_seo {
  width: 100%;
  margin-bottom: 10px;
}

.tx_map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
