<template>
  <!-- 实习报名 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习报名</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="h-contain">
        <div class="h-title2">{{ item_desc.plan_name }}</div>
        <div class="h-title3">
          {{ item_desc.user_name || item_desc.name }}&nbsp;&nbsp;&nbsp;&nbsp;
          {{ item_desc.student_sn }}
          <div class="h-title3_1">
            {{ item_desc.college_name }} / {{ item_desc.major_name }} /
            {{ item_desc.class_name }}
          </div>
        </div>
      </div>
      <div class="h2-contain">
        <div class="bodycontain">
          <el-form class="elform" ref="form" :rules="formRules" :model="formData" label-position="top">
            <p class="p-title">实习信息</p>
            <el-row :gutter="24" class="el-row-box">
              <el-col :md="12" :lg="8">
                <el-form-item label="实习单位" class="elformitem" prop="company">
                  <el-input v-model="formData.company" placeholder="请输入实习单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习岗位" class="elformitem" prop="job">
                  <el-input v-model="formData.job" placeholder="请输入实习岗位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习时间" class="elformitem" prop="timearr">
                  <el-date-picker v-model="formData.timearr" type="daterange" value-format="timestamp"
                    @change="selectTime" start-placeholder="实习开始时间" end-placeholder="实习结束时间"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习方式" class="elformitem" prop="mode">
                  <el-select class="elformitem" v-model="formData.mode" placeholder="请选择">
                    <el-option v-for="(item, index) in optiontwo" :key="index" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习地点" class="elformitem3" prop="address">
                  <el-input v-model="formData.address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="签到地点" class="elformitem3" prop="sign_in_address">
                  <div class="map" @click="checkbt">
                    <el-input v-model="formData.sign_in_address" placeholder="请选择" disabled></el-input>
                    <div class="elimage">
                      <el-image style="width: 30px; height: 30px" :src="mapicon"></el-image>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习待遇（元）" class="elformitem" prop="salary">
                  <el-input v-model="formData.salary" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习单位接收函" class="elformitem" :prop="ruleData.receiving_letter ? 'receiving_letter' : ''">
                  <div class="file-select-contain">
                    <div class="file-title">
                      {{formData.receiving_letter? "单位接收函已上传": "未选择文件"}}
                    </div>
                    <UploadFiles :showFile="false" :module="'practice_plan_freely_apply_info,url'" :btnMsg="formData.receiving_letter ? '重新上传' : '点击上传'" 
                    :cloudType="'practicePlanFreelyApplyInfo/url'" @setUrl="getUrl1" />
                    <div class="file-selecttip" @click="download(1)">
                      下载模板
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="安全承诺书" class="elformitem" :prop="ruleData.safety_letter ? 'safety_letter' : ''">
                  <div class="file-select-contain">
                    <div class="file-title">
                      {{ formData.safety_letter ? "安全承诺书已上传" : "未选择文件" }}
                    </div>
                    <UploadFiles :showFile="false" :module="'practice_plan_freely_apply_info,url'"
                      :btnMsg="formData.safety_letter ? '重新上传' : '点击上传'" :cloudType="'practicePlanFreelyApplyInfo/url'"
                      @setUrl="getUrl2" />
                    <div class="file-selecttip" @click="download(2)">
                      下载模板
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">实习单位信息</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :md="12" :lg="8">
                <el-form-item label="统一社会信用代码" prop="credit_code" class="elformitem">
                  <el-input v-model="formData.credit_code" placeholder="请输入" maxlength="18"
                    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="实习地区" prop="areaName" class="elformitem">
                  <vArea :area-ids="areaIds" @updateArea="changeArea" @areaCodes="areaCodes"></vArea>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="地区代码" prop="address_code" class="elformitem">
                  <el-input v-model="formData.address_code" placeholder="请输入" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="单位联系人" class="elformitem" prop="company_user">
                  <el-input v-model="formData.company_user" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="单位电话" class="elformitem" prop="company_tel">
                  <el-input v-model="formData.company_tel" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="单位地址" class="elformitem" prop="company_address">
                  <el-input v-model="formData.company_address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">家庭信息信息</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :md="12" :lg="8">
                <el-form-item label="家庭住址" class="elformitem" prop="family_address">
                  <el-input v-model="formData.family_address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="8">
                <el-form-item label="联系电话" class="elformitem" prop="family_tel">
                  <el-input v-model="formData.family_tel" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="p-title">申请理由</p>
            <el-row :gutter="20" class="el-row-box">
              <el-col :md="12" :lg="8">
                <el-form-item label="申请理由" class="elformitem" prop="apply_reason">
                  <el-input class="elinput" type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入内容"
                    v-model="formData.apply_reason"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="h6-contain">
        <el-button class="bt1" @click="cancel">取消</el-button>
        <el-button class="bt" @click="submit('form')">提交</el-button>
      </div>
    </div>

    <!-- 地图组件 -->
    <el-dialog title="选择签到地址" :visible.sync="dialogVisible" :close-on-click-modal="false" width="50%">
      <Map ref="map_info" :center-point="centerPoint" v-if="dialogVisible" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectMap">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getinternshipregistrationinfo,
  freelyapplyrules,
  areaGetList,
  addinternshipregistration,
} from "@/api/practiceplanfreelyapplyinfo";
// import Map from "@/components/baidu_map.vue";
import Map from "@/components/TencentMap/index.vue";
import vArea from "@/components/Area/index.vue";
import { get_report_deatil } from "@/api/practicestudentreport.js";
import UploadFiles from "@/components/upload-files";
export default {
  name: "mainpage",
  components: {
    UploadFiles,
    Map,
    vArea,
  },
  data() {
    return {
      formData: {
        id: "",
        practice_plan_id: "",
        company: "",
        job: "",
        timearr: [],
        start_date: "",
        end_date: "",
        mode: "",
        address: "",
        sign_in_address: "",
        lng: "",
        lat: "",
        salary: "",
        receiving_letter: "",
        safety_letter: "",
        company_user: "",
        company_tel: "",
        company_address: "",
        family_address: "",
        family_tel: "",
        apply_reason: "",
        credit_code: "", // 社会信用代码
        address_code: "", // 行政代码
        province_area_id: "", // 省级id
        city_area_id: "", // 市级id
        region_area_id: "", // 区级id
        areaName: "", // 用于判断是否为空
      },
      formRules: {
        company: [
          { required: true, message: "请填写实习单位", trigger: "blur" },
        ],
        job: [{ required: true, message: "请填写实习岗位", trigger: "blur" }],
        timearr: [
          {
            type: "array",
            required: true,
            message: "请选择实习时间",
            trigger: "change",
          },
        ],
        mode: [
          { required: true, message: "请选择实习方式", trigger: "change" },
        ],
        address: [
          { required: true, message: "请填写实习地点", trigger: "blur" },
        ],
        sign_in_address: [
          { required: true, message: "请填写签到地点", trigger: "blur" },
        ],
        salary: [
          { required: true, message: "请填写实习待遇", trigger: "blur" },
        ],
        receiving_letter: [
          { required: true, message: "请上传实习单位接收函", trigger: "blur" },
        ],
        safety_letter: [
          { required: true, message: "请上传安全承诺书", trigger: "blur" },
        ],
        credit_code: [
          {
            required: true,
            message: "请填写实习基地统一社会信用代码",
            trigger: "change",
          },
          { min: 18, message: "统一社会信用代码含18个字符", trigger: "blur" },
        ],
        areaName: [
          { required: true, message: "请选择实习地区", trigger: "blur" },
        ],
        company_user: [
          { required: true, message: "请填写单位联系人", trigger: "blur" },
        ],
        company_tel: [
          { required: true, message: "请填写单位电话", trigger: "blur" },
        ],
        company_address: [
          { required: true, message: "请填写单位地址", trigger: "blur" },
        ],
        family_address: [
          { required: true, message: "请填写家庭住址", trigger: "blur" },
        ],
        family_tel: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
        ],
        apply_reason: [
          { required: true, message: "请填写申请理由", trigger: "blur" },
        ],
      },
      ruleData: {}, // 规则
      centerPoint: {}, // 中心坐标点
      dialogVisible: false,
      optiontwo: [
        {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "参观",
        },
        {
          value: 2,
          label: "顶岗",
        },
        {
          value: 3,
          label: "跟岗",
        },
      ],
      mapicon: require("@/assets/more.png"),
      fileList: [],
      item_desc: {},
      areaIds: [],
    };
  },
  computed: {
    // 实习报名id，没有是添加，有是修改
    freely_apply_info_id() {
      return this.$route.query.freely_apply_info_id;
    },
    // 实习计划id
    plan_id() {
      return this.$route.query.plan_id;
    },
    student_id() {
      return this.$route.query.student_id;
    },
    type() {
      return this.$route.query.type;
    },
  },
  created() {
    this.formData.id = this.freely_apply_info_id;
    this.formData.practice_plan_id = this.plan_id;
    if (this.type == "编辑") {
      this.freely_info();
    } else {
      this.init_plan_info();
    }
    this.getFreelyRules()
  },
  methods: {
    async initArea(data) {
      let _this = this;
      await areaGetList({ pid: data }).then((res) => {
        if (res) {
          res.data.map((item, index) => {
            _this.options.push({
              value: item.id,
              label: item.name,
              area_code: item.area_code,
              children: [],
            });
          });
        }
      });
    },
    changeArea(data) {
      this.formData.province_area_id = data[0];
      this.formData.city_area_id = data[1];
      this.formData.region_area_id = data[2];
      this.formData.areaName = data[0];
    },
    // 行政代码
    areaCodes(data) {
      if (data.value === this.formData.region_area_id) {
        this.formData.address_code = data.area_code;
      }
    },
    // 获取实习计划信息与个人信息
    init_plan_info() {
      const params = {
        practice_plan_id: this.plan_id,
        student_id: this.student_id,
      };
      get_report_deatil(params).then((res) => {
        if (res.code === 0) {
          this.item_desc = res.data;
        }
      });
    },
    selectTime(date) {
      if (date && date.length) {
        this.formData.start_date = date[0] / 1000;
        this.formData.end_date = date[1] / 1000;
      } else {
        this.formData.start_date = "";
        this.formData.end_date = "";
      }
    },
    //报名详情
    freely_info() {
      const params = {
        id: this.freely_apply_info_id,
      };
      getinternshipregistrationinfo(params).then((res) => {
        if (res.code === 0) {
          this.item_desc = res.data.user_info;
          const data = res.data.info;
          this.areaIds = [];
          this.formData = {
            id: data.id,
            practice_plan_id: data.practice_plan_id,
            company: data.company,
            job: data.job,
            timearr: [data.start_date * 1000, data.end_date * 1000],
            start_date: data.start_date,
            end_date: data.end_date,
            mode: data.mode,
            address: data.address,
            sign_in_address: data.sign_in_address,
            lng: data.lng,
            lat: data.lat,
            salary: data.salary,
            receiving_letter: data.receiving_letter,
            safety_letter: data.safety_letter,
            company_user: data.company_user,
            company_tel: data.company_tel,
            company_address: data.company_address,
            family_address: data.family_address,
            family_tel: data.family_tel,
            apply_reason: data.apply_reason,
            credit_code: res.data.info.credit_code,
            address_code: res.data.info.address_code, // 行政代码
            province_area_id: res.data.info.province_area_id, // 省级id
            city_area_id: res.data.info.city_area_id, // 市级id
            region_area_id: res.data.info.region_area_id, // 区级id
          };
          this.formData.areaName = res.data.info.province_area_id;
          this.centerPoint = {
            address: data.address,
            lng: data.lng,
            lat: data.lat,
          };
          this.areaIds.push(
            res.data.info.province_area_id,
            res.data.info.city_area_id,
            res.data.info.region_area_id
          );
        }
      });
    },
    // 报名规则
    getFreelyRules() {
      const params = {
        practice_plan_id: this.plan_id,
      };
      freelyapplyrules(params).then((res) => {
        if (res.code === 0 && res.data) {
          this.ruleData = res.data;
        }
      });
    },
    // 返回
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    // 地图
    selectMap() {
      const mapInfo = this.$refs.map_info.ad_info;
      this.formData.lng = mapInfo.lng;
      this.formData.lat = mapInfo.lat;
      this.formData.sign_in_address = mapInfo.address;
      this.dialogVisible = false;
    },

    cancel() {
      if (this.type == "编辑") {
        this.$router.push({
          path: "/home/personalcenter/editorregistrationname",
          query: {
            freely_apply_info_id: this.freely_apply_info_id,
          },
        });
      } else {
        this.toback();
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.credit_code = this.formData.credit_code.toUpperCase();
          this.addinternshipregistration();
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },

    checkbt() {
      this.dialogVisible = true;
    },
    addinternshipregistration() {
      addinternshipregistration(this.formData)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.toback();
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    // 获取文件上传的返回值
    getUrl1(data, key) {
      this.formData.receiving_letter = data;
    },
    // 获取文件上传的返回值
    getUrl2(data, key) {
      this.formData.safety_letter = data;
    },
    download(status) {
      if (status == 1) {
        this.local(this.ruleData.receiving_letter, this.ruleData.receiving_letter_name);
      } else if (status == 2) {
        this.local(this.ruleData.safety_letter, this.ruleData.safety_letter_name);
      }
    },
    local(url, name) {
      var aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = url;
      aLink.target = "_blank";
      aLink.setAttribute("download", name);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink); //下载完成移除元素
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);

  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 60px;
    padding-left: 20px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }

    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }

  .line {
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    border-radius: 4px;

    .h-contain {
      min-height: 100px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .h-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }

        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .h-title2 {
        margin-top: 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }

      .h-title3 {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;

        .h-title3_1 {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    .h2-contain {
      margin-top: 20px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 0 20px;

      .h2-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }

        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .bodycontain {
        .elform {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 40px;

          .elformitem {
            margin-right: 40px;
            width: 400px;

            .file-select-contain {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .file-selecttip {
                padding-left: 10px;
                text-align: left;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                font-weight: 400;
                color: #3d84ff;
                cursor: pointer;
              }

              .file-title {
                margin-right: 10px;
                width: 50%;
                border: 1px solid #e4e7ed;
                height: 40px;
                overflow: hidden;
                border-radius: 4px;
                color: #666;
                padding-left: 10px;
              }
            }
          }

          .elformitem2 {
            margin-right: 10px;
            width: 180px;
          }

          .elformitem3 {
            margin-right: 40px;
            width: 400px;

            .map {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .elimage {
                margin-left: 10px;
                widows: 30px;
                height: 30px;
                cursor: pointer;
              }
            }
          }
        }

        .p-title {
          font-size: 18px;
          font-weight: bold;
        }

        .el-row-box {
          width: 100%;
          padding: 0 5%;
        }
      }
    }

    .h6-contain {
      margin-top: 20px;
      height: 80px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        background: #3d84ff;
        color: #ffffff;
      }

      .bt1 {
        background: #ffffff;
        color: #3d84ff;
      }
    }
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #ccc;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #ccc;
}

// ::v-deep .elformitem{
//   width: 410px !important;
// }</style>
